import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../config-firebase";
import { onSnapshot, collection } from "firebase/firestore";
import Navbar from "../component/navbar.jsx";
import CardSection from "../component/cardSection.jsx";
import CardSectionTwo from "../component/cardSectionTwo.jsx";
import Footer from "../component/footer.jsx";
import ButtonsDrincksWine from "../component/buttonsDrincksWine.jsx";
import ButtonsMenus from "../component/buttonsMenus.jsx";
import FlowerLoader from "../component/flowerLoader.jsx";
import style from "../styles/menu.module.css";
import sectionOrder from "../helpers/props.json";

function Menu() {
  const [menu, setMenu] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const params = useParams();

  const menuCollectionRef = collection(db, params.collection);

  useEffect(() => {
    const getCollection = onSnapshot(menuCollectionRef, (snapshot) => {
      const menuData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMenu(menuData);
      setFilteredItems(menuData.filter((dish) => dish.stock === true));
    });
    return () => getCollection();
  }, [params]);
  const color = params.color === "AA1803" ? "#274526" : `#${params.color}`;
  const groupedSection = useMemo(() => {
    // Determinamos el menú del día si la colección es "menudeldia"
    const sectionTodayMenu = params.collection === "menudeldia" ? [
      ...sectionOrder.sectionDish,
      ...sectionOrder.sectionDinks,
    ] : [];

    // Asignamos sectionOrderList basado en la colección
    let sectionOrderList;

    if (params.collection === "menudeldia") {
      sectionOrderList = sectionTodayMenu;
    } else if (params.collection === "menu" || params.collection === "menusintacc") {
      sectionOrderList = sectionOrder.sectionDish;
    } else if (params.collection === "cafeteria") {
      sectionOrderList = sectionOrder.sectionCoffe;
    } else if (params.collection === "bebidas") {
      sectionOrderList = sectionOrder.sectionDinks;
    } else if (params.collection === "vinos") {
      sectionOrderList = sectionOrder.sectionWine;
    }

    // Creamos el objeto agrupado basado en los ítems filtrados
    const grouped = filteredItems.reduce((acc, item) => {
      if (!acc[item.section]) {
        acc[item.section] = [];
      }
      acc[item.section].push(item);
      return acc;
    }, {});

    // Ordenamos las secciones agrupadas basado en sectionOrderList
    const orderedGrouped = {};
    sectionOrderList.forEach((section) => {
      if (grouped[section]) {
        orderedGrouped[section] = grouped[section];
      }
    });

    return orderedGrouped;
  }, [params.collection, filteredItems]);

  return (
    <div
      className={style.body}
      style={{
        color: color
      }}
    >
      <div className={style.main} style={{ color: color }}>
        <Navbar
          color={color}
          menuList={menu}
          setFilteredItems={setFilteredItems}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <p style={{ width: "80%", textAlign: "center", color: "#274526"}}>
            Por favor, infórmenos sobre cualquier alergia o intolerancia
            alimentaria.
          </p>
        </div>
        {!filteredItems.length ? (
          <FlowerLoader />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {Object.keys(groupedSection).map((section, index) =>
              index % 2 === 0 ? (
                <div key={section}>
                  <CardSection
                    color={color}
                    section={section}
                    dishList={groupedSection[section]}
                  />
                </div>
              ) : (
                <div key={section}>
                  <CardSectionTwo
                    color={color}
                    section={section}
                    dishList={groupedSection[section]}
                  />
                </div>
              )
            )}
          </div>
        )}
      </div>
      <div style={{ width: "100%", backgroundColor: "#EEE2CC" }}>
        {(params.collection === "menu" ||
          params.collection === "menusintacc") && (
          <ButtonsDrincksWine color={color} />
        )}
        {(params.collection === "bebidas" || params.collection === "vinos") && (
          <ButtonsMenus color={color} />
        )}
        {(params.collection === "menudeldia" || params.collection === "cafeteria") && (
          <div style={{height: "50px"}}>
          </div>
        )}
      </div>
      <Footer className={style.footer} />
    </div>
  );
}

export default Menu;