import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import imgBaner from "../assents/logo-blanco.png";
import style from "../styles/nabvar.module.css";
import FilterComponent from "./FilterComponent";
import SearchComponent from "./searchComponent";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import imgFlower from "../assents/flor-header-home1.png";
import sinTaccIcon from "../assents/sintacc.png";

function Navbar({ color, menuList, setFilteredItems }) {
  const [filterView, setFilterView] = useState(false);
  const [searchView, setSearchView] = useState(false);

  return (
    <div>
      <div className={style.nad} style={{ backgroundColor: color }}>
        <div className={style.filter}>
          <SortIcon
            onClick={() => setFilterView(!filterView)}
            style={{ fontSize: 30, cursor: "pointer", color: "white" }}
          />
          {filterView && (
            <FilterComponent
              menuList={menuList}
              setFilteredItems={setFilteredItems}
              setFilterView={setFilterView}
              desplegado={filterView}
              color={color} // Pasar estado de filtro vegano
            />
          )}
        </div>
        <div style={{ position: "absolute" }}>
          <Link to="/menu">
            <HomeIcon
              style={{ fontSize: 30, cursor: "pointer", color: "white" }}
            />
          </Link>
        </div>
        <div className={style.search}>
          {searchView && (
            <SearchComponent
              menuList={menuList}
              setFilteredItems={setFilteredItems}
            />
          )}
          <SearchIcon
            style={{ fontSize: 30, color: "white" }}
            onClick={() => setSearchView(!searchView)}
          />
        </div>
      </div>
      <div style={{ backgroundColor: color, height: "100%" }}>
        <div className={style.banner}>
          <img className={style.img} src={imgBaner} alt="Imagen del logo" />
          <div style={{width: "100%", display: "flex", alignItems: "center"}}>
          <p className={style.textBanner}>
          La tendencia global de la alimentación sin gluten crece día a día y nos sumamos a este movimiento 
          imparable por una vida más saludable, deliciosa y consciente.
          </p>
          <img
            style={{
              width: "30px",
              height: "30px",
            }}
            src={sinTaccIcon}
            alt=""
          />
          </div>
          <img
            className={style.imgTwo}
            src={imgFlower}
            alt="Imagen de Flores"
          />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
